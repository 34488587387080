<template>
  <div v-if="platform=='desktop' || platform=='tablet'" class="manage-workers">
      <div class="manage-workers-toolbar">
          <el-input v-model="search" style="width:30%;" placeholder="חיפוש - שם עובד/תז/תחילת עבודה/שיוך לחברה/כח אדם" />
          <input v-model="fromDate" type="date">
          <input v-model="toDate" type="date">
          <el-button @click="handle_export_to_scv" type="success" round>ייצוא לאקסל</el-button>
      </div>
      <div class="manage-workers-dashboard">
          <div class="dashboard-display">
              <h4>סך רשומות</h4>
              {{calculate_records.records_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}
          </div>
          <div class="dashboard-display">
              <h4>סכום 1</h4>
              {{calculate_records.amount_1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} &#8362;
          </div>
          <div class="dashboard-display">
               <h4>סכום 2</h4>
              {{calculate_records.amount_2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} &#8362;
          </div>
          <div class="dashboard-display">
               <h4>סכום 3</h4>
              {{calculate_records.amount_3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} &#8362;
          </div>
          <div class="dashboard-display">
               <h4>סכום 4</h4>
              {{calculate_records.amount_4.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} &#8362;
          </div>
          <div class="dashboard-display">
               <h4>סך תשלומים</h4>
              {{calculate_records.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} &#8362;
          </div>
      </div>
      <div class="manage-workers-options">
        <el-button @click="handle_get_by_status_data_from_db('פתוח')" type="success">פתוחים</el-button>
        <el-button @click="handle_get_by_status_data_from_db('סגור')" style="margin-right:5px;" type="danger">סגורים</el-button>
      </div>
      <div class="manage-workers-content">
          <table>
              <tr>
                  <th style="cursor:pointer;" @click="handleSortTable('שם העובד', !selectedSorted.order)">שם העובד</th>
                  <th style="cursor:pointer;" @click="handleSortTable('תז', !selectedSorted.order)">ת"ז/דרכון</th>
                  <th style="cursor:pointer;" @click="handleSortTable('תחילת עבודה', !selectedSorted.order)">תחילת עבודה</th>
                  <th>סיום עבודה</th>
                  <th>תשלום 1</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סכום-1', !selectedSorted.order)">סכום 1</th>
                  <th>תשלום 2</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סכום-2', !selectedSorted.order)">סכום 2</th>
                  <th>תשלום 3</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סכום-3', !selectedSorted.order)">סכום 3</th>
                  <th>תשלום 4</th>
                  <th style="cursor:pointer;" @click="handleSortTable('סכום-4', !selectedSorted.order)">סכום 4</th>
                  <th>סה"כ שולם</th>
                  <th style="cursor:pointer;" @click="handleSortTable('חברה', !selectedSorted.order)">שיוך לחברה/סניף</th>
                  <th style="cursor:pointer;"  @click="handleSortTable('כא', !selectedSorted.order)">חברת כ"א</th>
                  <th>הערות</th>
                  <th>סטטוס</th>
                  <th>פעולות</th>
              </tr>
              <h1 style="color:#333;" v-if="records.length==0">אין רשומות</h1>
              <template v-for="record in sorted_records" :key="record.uid">
                  <tr>
                      <td>{{record.full_name}}</td>
                      <td>{{record.worker_id}}</td>
                      <td>{{new Date(record.start_date.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td v-if="record.end_date">{{new Date(record.end_date.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td style="color:red;" v-if="!record.end_date">לא צויין</td>
                      <td v-if="record.tashlum_1">{{new Date(record.tashlum_1.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td style="color:red;" v-if="!record.tashlum_1">לא צויין</td>
                      <td>{{record.amount_1}}</td>
                      <td v-if="record.tashlum_2">{{new Date(record.tashlum_2.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td style="color:red;" v-if="!record.tashlum_2">לא צויין</td>
                      <td>{{record.amount_2}}</td>
                      <td v-if="record.tashlum_3">{{new Date(record.tashlum_3.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td style="color:red;" v-if="!record.tashlum_3">לא צויין</td>
                      <td>{{record.amount_3}}</td>
                      <td v-if="record.tashlum_4">{{new Date(record.tashlum_4.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td style="color:red;" v-if="!record.tashlum_4">לא צויין</td>
                      <td>{{record.amount_4}}</td>
                      <td>{{record.amount_1 + record.amount_2 + record.amount_3 + record.amount_4}}</td>
                      <td>{{record.branche}}</td>
                      <td v-if="record.hr_company!='חבר מביא חבר'">{{record.hr_company}}</td>
                      <td v-if="record.hr_company=='חבר מביא חבר'">{{record.hr_company}} ע"י: <br>
                          <span style="color:red;">{{record.employee_recommend}}</span><br>
                          <span style="color:red;">ת"ז: {{record.employee_recommend_id}}</span>

                      </td>
                      <td v-if="!record.notes">לא צויין</td>
                      <td style="color:red; white-space:pre-wrap;" v-if="record.notes">{{record.notes}}</td>
                      <td v-if="record.status=='פתוח'" style="color:green;">
                          {{record.status}}
                      </td>
                      <td v-if="record.status=='סגור'" style="color:red;">
                          {{record.status}}
                      </td>
                      <td>
                          <i @click="handle_record_setting(record)" class="material-icons setting-icon">
                            manage_accounts
                          </i>
                          <i @click="handle_delete_record(record)" class="material-icons delete-icon">
                            delete
                          </i>
                      </td>
                  </tr>
              </template>
          </table>
      </div>
      <DynamicFields @update="handle_update_records" @close="show_setting_dialog=false" v-if="show_setting_dialog" :data="selected_record" />
      <Loader v-if="is_pending" />
  </div>
    <OnlyForDesktop v-if="platform=='mobile'" />
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore} from '../../../../firebase/config'
import DynamicFields from '../components/DynamicFields.vue'
import Loader from '../../officeRequests/components/manage_side/loader/Loader.vue'
import {alert_confirm} from '../../../../Methods/Msgs'
import { computed } from '@vue/runtime-core'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import XLSX from "xlsx";
import store from '../../../../store'
export default {
    components:{DynamicFields,Loader,OnlyForDesktop},
    setup(){
        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        const fromDate = ref('')
        const toDate = ref('')
        const calculate_records = ref(computed(()=>{
            return {
                records_number:sorted_records.value.length,
                amount_1:sorted_records.value.reduce((p,c)=>p+ c.amount_1, 0).toFixed(1),
                amount_2:sorted_records.value.reduce((p,c)=>p+ c.amount_2, 0).toFixed(1),
                amount_3:sorted_records.value.reduce((p,c)=>p+ c.amount_3, 0).toFixed(1),
                amount_4:sorted_records.value.reduce((p,c)=>p+ c.amount_4, 0).toFixed(1),
                total:sorted_records.value.reduce((p,c)=>c.amount_1+c.amount_2+c.amount_3+c.amount_4 + p, 0).toFixed(1),
            }
        }))
        const is_pending = ref(false)
        const show_setting_dialog = ref(false)
        const selected_record = ref(null)

        const search = ref('')
        const records = ref([])
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sorted_records = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_search.value;
            if (selectedSorted.value.name == "שם העובד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.full_name >  b.full_name
                        ? 1
                        : b.full_name > a.full_name
                        ? -1
                        : 0;
                    }
                    return a.full_name > b.full_name
                    ? -1
                    : b.full_name  > a.full_name 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תז") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.worker_id >  b.worker_id
                        ? 1
                        : b.worker_id > a.worker_id
                        ? -1
                        : 0;
                    }
                    return a.worker_id > b.worker_id
                    ? -1
                    : b.worker_id  > a.worker_id 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תחילת עבודה") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.start_date.seconds * 1000) >  new Date(b.start_date.seconds * 1000)
                        ? 1
                        : new Date(b.start_date.seconds * 1000) > new Date(a.start_date.seconds * 1000)
                        ? -1
                        : 0;
                    }
                    return new Date(a.start_date.seconds * 1000) > new Date(b.start_date.seconds * 1000)
                    ? -1
                    : new Date(b.start_date.seconds * 1000)  > new Date(a.start_date.seconds * 1000) 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סכום-1") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.amount_1 >  b.amount_1
                        ? 1
                        : b.amount_1 > a.amount_1
                        ? -1
                        : 0;
                    }
                    return a.amount_1 > b.amount_1
                    ? -1
                    :  b.amount_1  > a.amount_1 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סכום-2") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.amount_2 >  b.amount_2
                        ? 1
                        : b.amount_2 > a.amount_2
                        ? -1
                        : 0;
                    }
                    return a.amount_2 > b.amount_2
                    ? -1
                    :  b.amount_2  > a.amount_2
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סכום-3") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.amount_3 >  b.amount_3
                        ? 1
                        : b.amount_3 > a.amount_3
                        ? -1
                        : 0;
                    }
                    return a.amount_3 > b.amount_3
                    ? -1
                    :  b.amount_3  > a.amount_3
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סכום-4") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.amount_4 >  b.amount_4
                        ? 1
                        : b.amount_4 > a.amount_4
                        ? -1
                        : 0;
                    }
                    return a.amount_4 > b.amount_4
                    ? -1
                    :  b.amount_4 > a.amount_4
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "חברה") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.branche >  b.branche
                        ? 1
                        : b.branche > a.branche
                        ? -1
                        : 0;
                    }
                    return a.branche > b.branche
                    ? -1
                    :  b.branche > a.branche
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "כא") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.hr_company >  b.hr_company
                        ? 1
                        : b.hr_company > a.hr_company
                        ? -1
                        : 0;
                    }
                    return a.hr_company > b.hr_company
                    ? -1
                    :  b.hr_company > a.hr_company
                    ? 1
                    : 0;
                });
            }
        }));

        const filter_by_search = ref(computed(()=>{
            if(search.value =='') {
                if(fromDate.value && toDate.value){
                    return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.start_date) &&
                        stringTodate(toDate.value)>=timestampToDate(record.start_date) ){
                            return record
                        }                    
                    })
                
                }
                return records.value;
            }else{     
                let results = records.value.filter((record)=>{
                    if(record.full_name.includes(search.value)) return record
                    if(record.worker_id.includes(search.value)) return record
                    if(record.branche.includes(search.value)) return record
                    if(record.hr_company.includes(search.value)) return record
                    if(new Date(record.start_date.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record
                })

                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.start_date) &&
                            stringTodate(toDate.value)>=timestampToDate(record.start_date) ){
                                return record
                        }
                    })
                }
                return results

            }
        }))
        const handle_delete_record = (record)=>{
            alert_confirm(`האם למחוק את ${record.full_name}?`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await projectFirestore.collection('Applications').doc('SpyoYmcAgbdKYsd8mJ66').collection('employee').doc(record.uid).delete()
                    const index = records.value.findIndex(_records=>_records.uid==record.uid)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                }
            })
        }
        const handle_update_records = ()=>{
            show_setting_dialog.value = false
            handle_get_by_status_data_from_db('פתוח')
        }
        const handle_record_setting = (record)=>{
            selected_record.value = record
            show_setting_dialog.value = true
        }
        const handle_get_by_status_data_from_db = async(status)=>{
            is_pending.value = true
            const docs = await projectFirestore.collection('Applications').doc('SpyoYmcAgbdKYsd8mJ66').collection('employee')
            .where('status','==',status).orderBy('start_date','asc').get()
            records.value = docs.docs.map(doc=>doc.data())
            is_pending.value = false
        }

        const handle_export_to_scv = ()=>{
            let rows = JSON.parse(JSON.stringify(sorted_records.value))

            let workbook = XLSX.utils.book_new();

            let workSheetData =[]
            workSheetData.push([
                        'שם העובד',
                        'תז/דרכון',
                        'תחילת עבודה',
                        'סיום עבודה',
                        'תשלום 1',
                        'סכום 1',
                        'תשלום 2',
                        'סכום 2',
                        'תשלום 3',
                        'סכום 3',
                        'תשלום 4',
                        'סכום 4',
                        'סהכ שולם',
                        'שיוך לחברה/סניף',
                        'חברת כא',
                        'הערות',
                        'סטטוס'
            ])
            
            let calaculate_amount_1=0,calaculate_amount_2=0,calaculate_amount_3=0,calaculate_amount_4=0,total=0
        
            rows.forEach((row)=>{
                workSheetData.push([
                    row.full_name,
                    row.worker_id,
                    row.start_date?new Date(row.start_date.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.end_date?new Date(row.end_date.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.tashlum_1?new Date(row.tashlum_1.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.amount_1,
                    row.tashlum_2?new Date(row.tashlum_2.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.amount_2,
                    row.tashlum_3?new Date(row.tashlum_2.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.amount_3,
                    row.tashlum_4?new Date(row.tashlum_2.seconds*1000).toLocaleDateString('he').split('.').join('/'):'לא צויין',
                    row.amount_4,
                    row.amount_1+row.amount_2+row.amount_3+row.amount_4,
                    row.branche?row.branche:'לא צויין',
                    row.hr_company?row.hr_company:'לא צויין',
                    row.notes?row.notes:'לא צויין',
                    row.status
                    
                ])
                calaculate_amount_1+=row.amount_1
                calaculate_amount_2+=row.amount_2
                calaculate_amount_3+=row.amount_3
                calaculate_amount_4+=row.amount_4
                total +=  row.amount_1+row.amount_2+row.amount_3+row.amount_4
            })

            workSheetData.push(['סהכ',rows.length,'','','',calaculate_amount_1,'',calaculate_amount_2,'',calaculate_amount_3,'',calaculate_amount_4,total])

            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "ניהול כא");
            XLSX.writeFile(workbook, "ניהול כא.xlsx");
        }
         // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        handle_get_by_status_data_from_db('פתוח')

        return{
            platform,
            search,
            records,
            show_setting_dialog,
            handle_record_setting,
            handle_get_by_status_data_from_db,
            handle_update_records,
            handle_delete_record,
            selected_record,
            is_pending,
            filter_by_search,
            selectedSorted,
            sorted_records,
            handleSortTable,
            handle_export_to_scv,
            calculate_records,
            fromDate,
            toDate
        }
    }
}
</script>

<style scoped>
    .manage-workers{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #fff;
    }
    .manage-workers-toolbar{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
    }
    .manage-workers-options{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .manage-workers-dashboard{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .dashboard-display{
        width: 200px;
        height: 100%;
        border-radius: 5px;
        background: var(--purple);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:#fff;
    }
    .manage-workers-content{
        width: 100%;
        height: calc(100% - 180px);
        overflow: hidden;
        overflow-y: auto;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
    .setting-icon{
        cursor: pointer;
    }
    .delete-icon{
        color: crimson;
        margin-right: 5px;
        cursor: pointer;
    }
    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
</style>