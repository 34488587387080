<template>
  <div class="create-new-worker">
      <div class="create-new-worker-header">
          <h1>טופס כח אדם</h1>
      </div>
      <div class="create-new-worker-content">
          <div class="content-field">
              <h2>שם מלא <span style="color:red;">*</span></h2>
              <el-input v-model="values.full_name" placeholder="שם מלא" />
          </div>
          <div class="content-field">
              <h2> תז/דרכון <span style="color:red;">*</span></h2>
              <el-input v-model="values.worker_id" placeholder="תז/דרכון" />
          </div>
          <div class="content-field">
              <h2> תאריך תחילת עבודה <span style="color:red;">*</span></h2>
              <input v-model="values.start_date" type="date" style="width:100%;" name="" id="">
          </div>
          <div class="content-field">
              <h2> תאריך סיום עבודה</h2>
              <input v-model="values.end_date" type="date" style="width:100%;" name="" id="">
          </div>
          <div class="content-field">
              <h2>מקור המועמד <span style="color:red;">*</span></h2>
                <el-select style="width:100%;" v-model="values.hr_company" clearable filterable placeholder="חברת כח אדם">
                    <el-option
                        v-for="company in hr_companies"
                        :key="company"
                        :value="company"
                    >
                    </el-option>
                </el-select>
          </div>
          <div v-if="values.hr_company=='חבר מביא חבר'" class="content-field">
            <h2>שם העובד הממליץ <span style="color:red;">*</span></h2>
            <el-input v-model="values.employee_recommend" placeholder="שם מלא" />
          </div>
          <div v-if="values.hr_company=='חבר מביא חבר'" class="content-field">
            <h2>תז/דרכון של העובד הממליץ <span style="color:red;">*</span></h2>
            <el-input v-model="values.employee_recommend_id" placeholder="תז/דרכון" />
          </div>
          <div class="content-field">
              <h2>שיוך לחברה/סניף <span style="color:red;">*</span></h2>
                <el-select style="width:100%;" v-model="values.branche" clearable filterable placeholder="שייך לחברה/סניף">
                    <el-option
                        v-for="branche in branches"
                        :key="branche"
                        :value="branche"
                    >
                    </el-option>
                </el-select>
          </div>
          <div class="content-field">
              <h2>הערות</h2>
                <el-input
                    v-model="values.notes"
                    style="white-space: pre-wrap;"
                    :rows="3"
                    type="textarea"
                    placeholder="הערות..."
                />
          </div>
          <div class="content-field">
               <el-button @click="handle_submit" style="width:100%;" type="success">עדכון</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_hr_companies_from_db,get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import {projectFirestore} from '../../../../firebase/config'
export default {
    setup(){
        
        const error_msg = ref('')
        const hr_companies = ref([])
        const branches = ref([])
        
        const values = ref({
            created_at:new Date(),
            full_name:'',
            worker_id:'',
            start_date:'',
            end_date:'',
            hr_company:'',
            branche:'',
            notes:'',
            employee_recommend:'',
            employee_recommend_id:'',
            tashlum_1:'',
            amount_1:0,
            tashlum_2:'',
            amount_2:0,
            tashlum_3:'',
            amount_3:0,
            tashlum_4:'',
            amount_4:0,
            status:'פתוח'
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'full_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם מלא'
                            return false
                        }
                        break;
                    case 'worker_id':
                        if(!value){
                            error_msg.value = 'עליך להזין תז/דרכון'
                            return false
                        }
                        break;
                    case 'start_date':
                        if(!value){
                            error_msg.value = 'עליך להזין תאריך התחלה'
                            return false
                        }
                        break;
                    case 'hr_company':
                        if(!value){
                            error_msg.value = 'עליך לבחור את מקור המועמד'
                            return false
                        }
                        if(value=="חבר מביא חבר"){
                            if(!values.value.employee_recommend){
                                error_msg.value = 'עליך להזין שם מלא של שם העובד הממליץ'
                                return false
                            }
                            if(!values.value.employee_recommend_id){
                                error_msg.value = 'עליך להזין את תז/דרכון של העובד הממליץ'
                                return false
                            }
                        }
                        break;
                    case 'branche':
                        if(!value){
                            error_msg.value = 'עליך לבחור חברה/סניף'
                            return false
                        }
                        break; 
                }
            }

            return true
        }
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                console.log('הכל תקין');
                await save_data_in_db()
            }
        }

        const save_data_in_db = async()=>{
            values.value.start_date = new Date(values.value.start_date)
            if(values.value.end_date){
                 values.value.end_date = new Date(values.value.end_date)
            }

            const doc = projectFirestore.collection('Applications').doc('SpyoYmcAgbdKYsd8mJ66')
            .collection('employee').doc()
            await doc.set({
                ...values.value,
                uid:doc.id
            })

            alert('success','הצלחה',
            `העובד ${values.value.full_name} נשמר בהצלחה`)
            .then(()=>{
                reset()
            })
        }

        const reset = ()=>{
            values.value = {
                created_at:new Date(),
                full_name:'',
                worker_id:'',
                start_date:'',
                end_date:'',
                hr_company:'',
                branche:'',
                notes:'',
                employee_recommend:'',
                employee_recommend_id:'',
                tashlum_1:'',
                amount_1:0,
                tashlum_2:'',
                amount_2:0,
                tashlum_3:'',
                amount_3:0,
                tashlum_4:'',
                amount_4:0,
                status:'open'
            }
        }
        const init = async()=>{
            hr_companies.value = await get_hr_companies_from_db()
            branches.value = await get_branches_from_db()
        }

        init()
        return{
            hr_companies,
            branches,
            values,
            handle_submit,
            error_msg
        }
    }
}
</script>

<style scoped>
    .create-new-worker{
        padding: 0 5px;
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        color: #fff;
    }
    .create-new-worker-header{
        width: 100%;
        height: 50px;
        background: var(--success);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 5px;
        
    }
    .create-new-worker-content{
        padding: 5px 0;
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
    }
    .content-field{
        width: 100%;
        height: fit-content;
        margin-top:10px;
    }

    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
</style>