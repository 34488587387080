<template>
  <div class="hr-resource-main">
      <div v-if="state==null" class="main-btns">
          <button @click="state='ManageWorkers'" class="btn btn-succes">
              דוח כללי
          </button>
          <button @click="state='CreateNewWorker'" class="btn btn-warning">
               עובד חדש
          </button>
      </div>
      <div v-if="state!=null" class="main-state">
          <component :is="state"/>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import CreateNewWorker from '../Hr_Resource/components/CreateNewWorker.vue'
import ManageWorkers from '../Hr_Resource/components/ManageWorkers.vue'

export default {
    components:{CreateNewWorker,ManageWorkers},
    setup(){
        const state = ref(null)
        return{state}
    }
}
</script>

<style scoped>
    .hr-resource-main{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .main-btns{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        display:  flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn{
        width: 300px;
        height: 100px;
        border: 0;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 25px;
        cursor: pointer;
    }

    .btn-succes{
        background: var(--success);
        color: #fff;
    }
    .btn-warning{
        background: var(--yellow);
    }
    .main-state{
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

</style>