<template>
  <div class="dynamic-field">
      <div class="dynamic-field-dialog slide-in-top">
          <div class="dynamic-field-dialog-header">
              <h2>שם העובד: {{record_data.full_name}}</h2>
              <div @click="handle_close" class="close-btn">
                  X
              </div>
          </div>
          <div class="dynamic-field-dialog-content">
            <div class="dynamic-field-dialog-content-field">
              <h2>מקור המועמד</h2>
                <el-select style="width:100%;" v-model="values.hr_company" clearable filterable placeholder="חברת כח אדם">
                    <el-option
                        v-for="company in hr_companies"
                        :key="company"
                        :value="company"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תז/דרכון</h3>
            <el-input v-model="values.worker_id" placeholder="תז/דרכון" />
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תאריך סיום</h3>
                <input style="width:100%;" type="date" v-model="values.end_date">
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תשלום 1</h3>
                <input style="width:100%;" type="date" v-model="values.tashlum_1">
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>סכום 1</h3>
                <el-input-number style="width:100%" v-model="values.amount_1" :step="0.01"/>
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תשלום 2</h3>
                <input style="width:100%;" type="date" v-model="values.tashlum_2">
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>סכום 2</h3>
                <el-input-number style="width:100%" v-model="values.amount_2" :step="0.01"/>
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תשלום 3</h3>
                <input style="width:100%;" type="date" v-model="values.tashlum_3">
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>סכום 3</h3>
                <el-input-number style="width:100%" v-model="values.amount_3" :step="0.01"/>
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>תשלום 4</h3>
                <input style="width:100%;" type="date" v-model="values.tashlum_4">
            </div>
            <div class="dynamic-field-dialog-content-field">
                <h3>סכום 4</h3>
                <el-input-number style="width:100%" v-model="values.amount_4" :step="0.01"/>
            </div>
            <div class="dynamic-field-dialog-content-field">
            <h3>סטטוס</h3>
            <el-select style="width:100%;" v-model="values.status" class="m-2" placeholder="Select" size="large">
                <el-option
                v-for="status in status_options"
                :key="status"
                :value="status"
                >
                </el-option>
            </el-select>
            </div>
            <div class="dynamic-field-dialog-content-field">
                <el-button @click="handle_submit" style="width:100%;" type="success">עדכון</el-button>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../../firebase/config'
import {get_hr_companies_from_db} from '../../../../Methods/rosman_shivuk_funcs'
export default {
    props:['data'],
    emits:['close','update'],
    setup(props,{emit}){

        const hr_companies = ref([])
        const status_options = ref([
            'פתוח',
            'סגור'
        ])
        const record_data = ref(null)
        const handle_close = ()=>{
            emit('close')
        }

        const values = ref( {
            hr_company:'',
            worker_id:'',
            end_date:'',
            tashlum_1:'',
            tashlum_2:'',
            tashlum_3:'',
            tashlum_4:'',
            amount_1:0,
            amount_2:0,
            amount_3:0,
            amount_4:0,
            status:'',
            uid:''
        })

        const prepare_data_to_use = ()=>{
            record_data.value = JSON.parse(JSON.stringify(props.data))
            values.value.hr_company = record_data.value.hr_company
            values.value.worker_id =  record_data.value.worker_id
            if(record_data.value.end_date){
                values.value.end_date = new Date(record_data.value.end_date.seconds * 1000).toISOString().split("T")[0]
            }
            if(record_data.value.tashlum_1){
                values.value.tashlum_1 = new Date(record_data.value.tashlum_1.seconds * 1000).toISOString().split("T")[0]
            }
            if(record_data.value.tashlum_2){
                values.value.tashlum_2 = new Date(record_data.value.tashlum_2.seconds * 1000).toISOString().split("T")[0]
            }
            if(record_data.value.tashlum_3){
                values.value.tashlum_3 = new Date(record_data.value.tashlum_3.seconds * 1000).toISOString().split("T")[0]
            }
            if(record_data.value.tashlum_4){
                values.value.tashlum_4 = new Date(record_data.value.tashlum_4.seconds * 1000).toISOString().split("T")[0]
            }
            values.value.amount_1 = record_data.value.amount_1
            values.value.amount_2 = record_data.value.amount_2
            values.value.amount_3 = record_data.value.amount_3
            values.value.amount_4 = record_data.value.amount_4
            values.value.status = record_data.value.status
            values.value.uid = record_data.value.uid
        }

        const handle_submit = async()=>{
            prepare_data_before_save_in_db()
            console.log(values.value);
            await save_data_in_db()
            emit('update')
        }

        const save_data_in_db = async()=>{
            await projectFirestore.collection('Applications').doc('SpyoYmcAgbdKYsd8mJ66')
            .collection('employee').doc(values.value.uid)
            .set(values.value,{merge:true})
        }
        const prepare_data_before_save_in_db = ()=>{
            if(values.value.end_date){
                values.value.end_date = new Date(values.value.end_date)
            }
            if(values.value.tashlum_1){
                values.value.tashlum_1 = new Date(values.value.tashlum_1)
            }
            if(values.value.tashlum_2){
                values.value.tashlum_2 = new Date(values.value.tashlum_2)
            }
            if(values.value.tashlum_3){
                values.value.tashlum_3 = new Date(values.value.tashlum_3)
            }
            if(values.value.tashlum_4){
                values.value.tashlum_4 = new Date(values.value.tashlum_4)
            }

        }

        const init = async()=>{
            hr_companies.value = await get_hr_companies_from_db()
        }

        init()

        prepare_data_to_use()
        return{
            record_data,
            handle_close,
            values,
            status_options,
            handle_submit,
            hr_companies
        }
    }
}
</script>

<style scoped>
    .dynamic-field{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .dynamic-field-dialog{
        width: 500px;
        height: 80%;
        border-radius: 10px;
        background: #fff;
    }
    .dynamic-field-dialog-header{
        position: relative;
        width: 100%;
        height: 50px;
        border-radius: 10px 10px 0 0 ;
        background: var(--success);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .close-btn{
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background: var(--danger);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
    }

    .dynamic-field-dialog-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        color: #333;
        padding: 5px 0;
    }
    .dynamic-field-dialog-content::-webkit-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        display: none;
    }

    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }

    .dynamic-field-dialog-content-field{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-top: 5px;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>